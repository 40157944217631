import React, { useState, useContext, useCallback, useEffect } from "react";
import axios from 'axios';
import Header from "./componentes/Header";
import PersistentDrawerRight from "./componentes/PersistentDrawerRight";
import Post from "./componentes/Post";
import { db } from "../firebase/firebase";
import img1 from "../Uploading-bro.png";
import img2 from "../Queue-amico.png";
import { UserProvider, UserContext } from "./componentes/UserContext";
import Fileupload from "./componentes/Fileupload";
import ProfileUpdateModal from "./componentes/ProfileUpdateModal";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import confirm from "../order_confirmed.png";
import notfound from "../notfound.png";
import reviewe from "../undraw_loading_frh4.png";
import cancel from "../cancel.png";
import ReactCardFlip from 'react-card-flip';
import { Button, StepContent, TextField } from "@material-ui/core";
import LinearProgress from "@material-ui/core/LinearProgress";
const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: "auto",
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  btn: {
    backgroundColor: "#eca742",
    color: "#fff",
    marginTop: 10,
    marginBottom: "10px",
    minHeight:'30px'
  },
  form: {
    display: "flex",
    flexDirection: "column",
  },
  input: {
    marginBottom: 10,
  },
  alert: {
    marginBottom: 10,
  },
}));

// import { useDropzone } from "react-dropzone";

// function MyDropzone() {
//   const onDrop = useCallback((acceptedFiles) => {
//     console.log(acceptedFiles);
//   }, []);
//   const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

//   return (
//     <div className="containerC">
//       <div>
//         <div {...getRootProps()} className="drop-container">
//           <input {...getInputProps()} />
//           {isDragActive ? (
//             <p>Drop the files here ...</p>
//           ) : (
//             <p>Drag and drop your content, or click to select files</p>
//           )}
//         </div>
//       </div>
//     </div>
//   );
// }

const DropZone = () => {
  return (
    <div className="containerC">
      <div className="drop-container">
        <div className="drop-message">
          <div className="upload-icon"></div>
          Drag & Drop files here or click to upload
        </div>
      </div>
    </div>
  );
};

function Home(props) {

  const [isFlipped,setFilpped] =useState(true)

  const [post, setPost] = useState([]);
  const classes = useStyles();
  const userAuth = useContext(UserContext);

  const dataVal = useContext(UserContext);
  const [title, setTitle] = useState("Click here to select your content");
  const [file, setFile] = useState();
  const [state, setState] = useState(true);
  const [progress, setProgress] = React.useState(0);
  const [username, setUsername] = useState();
  const [email, setEmail] = useState();
  const [dob, setDob] = useState();
  const [addr, setAdd] = useState();
  const [gard, setGard] = useState();
  const [mno, setMno] = useState();
  const [id, setId] = useState();
  const [pic, setPic] = useState();
  const [vid, setVid] = useState();
  const [ids, setIds] = useState();
  const [status, setStatus] = useState();
  const [picChange, setPicChange] = useState(img2);
  const [content, setContent] = useState("Know Application status");
  const [load, setLoad] = useState(true);
  useEffect(() => {
    db.collection('user/').doc(userAuth.email).onSnapshot((doc) => {
          setIds(doc.id);
          setUsername(doc.data().name);
          setEmail(doc.data().email);
          var dp = doc.data().dob;
          console.log(dp.toDate());
          setDob(dp.toDate());
          setAdd(doc.data().addr);
          setGard(doc.data().gard);
          setMno(doc.data().no);
          setId(doc.data().id);
          setPic(doc.data().propic);
          setVid(doc.data().vid);
          setStatus(doc.data().status);
      });
  }, []);

  function fileSelect(e) {
    var path = ((e.target.files[0]));
    
    console.log(path)
    if (e.target.files[0].size / (1024 * 1024) <= 600) {
      const fileName = e.target.files[0].name.length>35?e.target.files[0].name.slice(0, 35)+'..':e.target.files[0].name
      setTitle(fileName + " Selected");
      setState(false);
      setFile(e.target.files[0]);
    }
  }

  function FileuploadEvent() {

    if (
      window.confirm(
        "Are you sure to upload the content.You cannot change it later"
      )
    ) {
      setLoad(false);
      var formdata = new FormData();
      // formdata.append("vid", file,file.name);
      // formdata.append("userid", userAuth.uid);
      formdata.append("vid", file);
      formdata.append("userid", userAuth.uid);
      let ext = (file.type).split("/")[1]
      if(ext=='quicktime'){
        ext="MOV"
      }
      formdata.append("type",ext);

      var requestOptions = {
        method: 'POST',
        body: formdata,
        redirect: 'follow'
      };

     
      // var requestOptions = {
      //   method: "POST",
      //   body: formdata,
      //   redirect: "follow",
      //   'Content-Type':"multipart/form-data"
      // };

      setState(true);
      fetch("https://api.applyatcht.com/upload", requestOptions)
        .then((response) => {
          let links = "https://api.applyatcht.com/stream/" + userAuth.uid+'.'+ext;
          db.collection("user/")
            .doc(email)
            .set({
              email: email,
              name: username,
              no: mno,
              dob: dob,
              addr: addr,
              gard: gard,
              id: id,
              propic: pic,
              vid: links,
              status:2
            })
            .then(() => {
              setLoad(true);
              setTimeout(function(){ window.location.reload(); }, 2000);
              
            }).catch((error) => console.log(error));;
        })
        .catch((error) => console.log(error));
    }
  }

  function statusUpdate() {
    if (status == 1) {
      setPicChange(confirm);
      setContent("Congrats you are selected");
    } else if (status == 0) {
      setPicChange(notfound);
      setContent("You haven't submitted your content!");
    } else if (status == 2) {
      setPicChange(reviewe);
      setContent("You are on the waiting list!");
    } else if (status == 3) {
      setPicChange(cancel);
      setContent("You application has been rejected.Please apply next time");
    }
    onFlipClick()
  }

  function onFlipClick(){
    setFilpped(e=>!e)
  }
  return (
    <div>
      {/* <Header props={props} user={userAuth.displayName} /> */}

      <PersistentDrawerRight />
      <div className="container1">
        <div className="row_cent">
          <div className="files">
            {vid ? (
              <video width="100%" height="100%" controls src={vid} ></video>
            ) : (
              <>
                <div>
                  <img src={img1} className="files_img" />
                </div>
                <div className="inputContainer">
                  <input
                    onChange={fileSelect}
                    accept="video/mp4"
                    className={classes.input}
                    id="contained-button-file"
                    type="file"
                    style={{ display: "none" }}
                    directory webkitdirectory mozdirectory
                  />
                  <label>Content size should be &#60; 600MB </label>
                  <label htmlFor="contained-button-file">
                    <a className="proselect_1">{title}</a>
                  </label>
                  <Button
                    type="submit"
                    disabled={state}
                    className={classes.btn}
                    id="btn"
                    onClick={FileuploadEvent}
                    
                  >
                    Upload File{" "}
                    <CircularProgress style={{ display: load ? "none" : "" }} />
                  </Button>
                  {/* <LinearProgress variant="determinate" style={{ width: "100%" }} max="100" value={progress} /> */}
                </div>
              </>
            )}
          </div>
          <ReactCardFlip isFlipped={isFlipped} flipDirection="horizontal">
            <div className="files">
              <div>
                <img src={picChange} className="files_img" />
              </div>
              <div className="inputContainer">
                <a className="proselect_1" onClick={() => statusUpdate()}>
                  {content}
                </a>
              </div>
            </div>
            <div className="files">
              <div>
                <img src={img2} className="files_img" />
              </div>
              <div className="inputContainer">
                <a className="proselect_1" onClick={() => statusUpdate()}>
                Know Application status
                </a>
              </div>
            </div>
          </ReactCardFlip>
        </div>
      </div>
    </div>
  );
}

export default Home;
