import React, { useState, useEffect, createContext } from "react";
import { auth ,db} from "../../firebase/firebase";

export const UserContext = createContext();

export const UserProvider = (props) => {
  const [userAuth, setUser] = useState();
  const [data,setData] =useState();
  useEffect(() => {
    auth.onAuthStateChanged((authUser) => {
      if(authUser){
        let email = authUser.email
          db.collection("user/")
          .doc(email)
          .onSnapshot((doc) => {
            if(doc.data()){
              setUser(authUser)
            }else{
              console.log('invalid')
            }
          });
        }
    });
    
    
  }, [userAuth]);
  return (
    <UserContext.Provider value={userAuth} dataVal={data} setUser={setUser}>
      {props.children}
    </UserContext.Provider>
  );
};
