import React, { useState, useEffect, useCallback, useContext } from "react";
import './modal.css'
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import { Button, TextField } from "@material-ui/core";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import { UserProvider, UserContext } from "./UserContext";
import { auth, db,storage } from "../../firebase/firebase";
import ListItemText from "@material-ui/core/ListItemText";
import LinearProgress from '@material-ui/core/LinearProgress';
import useMediaQuery from '@material-ui/core/useMediaQuery';

let matches = false;
const RunG=()=>{
  matches = useMediaQuery('(min-height:780px)');
  return matches
}

const useStyles = makeStyles((theme) =>({
    root: {
      maxWidth: 'auto',
    },
    bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)',
    },
    title: {
      fontSize: 14,
    },paper: {
        marginBottom:RunG?'0vh':'5vh',
        position: 'absolute',
        width: 400,
        maxHeight:'90vh',
        backgroundColor: theme.palette.background.paper,
        
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
      },
    pos: {
      marginBottom: 12,
    },btn: {
        backgroundColor: "#eca742",
        color: "#fff",
        marginTop: 10,
        marginBottom: "10px",
      },
      form: {
        display: "flex",
        flexDirection: "column",
      },
      input: {
        marginBottom: 10,
      },
      alert: {
        marginBottom: 10,
      },
  }));

export default function ProfileUpdateModal({show,close}) {
  const userAuth = useContext(UserContext);

  const [progress, setProgress] = React.useState(0);
    const classes = useStyles();
    const [password, setPassword] = useState();
    const [confpassword, setConfPassword] = useState();
    
    const [state, setState] = useState(false);
    const [ids,setIds] =useState()

    const [modalStyle] = React.useState({
        top: `50%`,
        left: `50%`,
        transform: `translate(-50%, -50%)`,
      });
    const [open, setOpen] = React.useState(show);
  
    const handleOpen = () => {
      setOpen(true);
    };
    
    const handleClose = () => {
      setOpen(false);
      
      close()
    };

   const Update = async (e) => {
    e.preventDefault();
      if(password===confpassword){
        auth.currentUser.updatePassword(password).then(()=>{
          handleClose()
        })
      }else{
        alert("The password and confirmation password do not match")
      }
    };
    const body = (
        <div style={modalStyle} className={classes.paper}>
            <form className={classes.form} onSubmit={Update}>
            <TextField
          onChange={(e) => {
            setPassword(e.target.value);
          }}
          required={true}
          type="password"
          value={password}
          variant="outlined"
          label="Password"
          minLength={6}
          maxLength={12}
          className={classes.input}
        />
        <TextField
          onChange={(e) => {
            setConfPassword(e.target.value);
          }}
          minLength={6}
          maxLength={12}
          required={true}
          type="password"
          value={confpassword}
          variant="outlined"
          label="Confirm Password"
          className={classes.input}
        />
        <Button
          type="submit"
          disabled={state ? true : false}
          className={classes.btn}
          id="btn"
        >
          Update Password
        </Button>
        <LinearProgress variant="determinate" style={{ width: "100%" }} max="100" value={progress} />
        </form>
        </div>
      );

      useEffect(()=>{
        
        if(show){
          handleOpen()
        }else{
          handleClose()
        }
      },[show])
    return (
        <div>
      <Modal
        open={open}
        onClose={handleClose}
        disablePortal
  disableEnforceFocus
  disableAutoFocus
  aria-labelledby="server-modal-title"
  aria-describedby="server-modal-description"
      >
        {body}
      </Modal>
    </div>
    )
}
