import React, { useState, useEffect, useCallback, useContext } from "react";
import "./modal.css";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import { Button, TextField } from "@material-ui/core";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { UserProvider, UserContext } from "./UserContext";
import { auth, db, storage } from "../../firebase/firebase";
import ListItemText from "@material-ui/core/ListItemText";
import LinearProgress from "@material-ui/core/LinearProgress";
import useMediaQuery from "@material-ui/core/useMediaQuery";

let matches = false;
const RunG = () => {
  matches = useMediaQuery("(min-height:780px)");
  return matches;
};

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: "auto",
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  paper: {
    marginBottom: RunG ? "0vh" : "5vh",
    position: "absolute",
    width: 400,
    maxHeight: "90vh",
    backgroundColor: theme.palette.background.paper,

    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  pos: {
    marginBottom: 12,
  },
  btn: {
    backgroundColor: "#eca742",
    color: "#fff",
    marginTop: 10,
    marginBottom: "10px",
  },
  form: {
    display: "flex",
    flexDirection: "column",
  },
  input: {
    marginBottom: 10,
  },
  alert: {
    marginBottom: 10,
  },
}));

export default function ProfileUpdateModal({ show, close }) {
  const userAuth = useContext(UserContext);
  console.log(RunG());
  const [progress, setProgress] = React.useState(0);
  const classes = useStyles();
  const [username, setUsername] = useState();
  const [email, setEmail] = useState();
  const [dob, setDob] = useState();
  const [addr, setAdd] = useState();
  const [gard, setGard] = useState();
  const [mno, setMno] = useState();
  const [id, setId] = useState();
  const [pic, setPic] = useState();
  const [vid, setVid] = useState();
  const [state, setState] = useState(false);
  const [ids, setIds] = useState();
  const [status, setStatus] = useState(1);

  const [modalStyle] = React.useState({
    top: `50%`,
    left: `50%`,
    transform: `translate(-50%, -50%)`,
  });
  const [open, setOpen] = React.useState(show);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);

    close();
  };

  useEffect(() => {
    try{
    db.collection("user/")
      .doc(userAuth.email)
      .onSnapshot((doc) => {
        console.log(doc);
        setIds(doc.id);
        setUsername(doc.data().name);
        setEmail(doc.data().email);
        var dp = doc.data().dob;
        console.log(dp.toDate());
        setDob(dp.toDate());
        setAdd(doc.data().addr);
        setGard(doc.data().gard);
        setMno(doc.data().no);
        setId(doc.data().id);
        setPic(doc.data().propic);
        setVid(doc.data().vid);
        setStatus(doc.data().status);
      }).catch(console.log);
    }catch(e){
      console.log(e)
    }
    // db.collection(userAuth.email).get().then((querySnapshot) => {
    //   querySnapshot.forEach((doc) => {

    //   });
    // });
    if (show) {
      handleOpen();
    } else {
      handleClose();
    }
  }, [show]);

  const [file, setFile] = useState(null);
  const [image, setImage] = useState(null);
  const handleChange = (e) => {
    if (e.target.files[0]) {
      setFile(URL.createObjectURL(e.target.files[0]));
      console.log(URL.createObjectURL(e.target.files[0]));
      setImage(e.target.files[0]);
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.readyState === 2) {
          setFile(reader.result);
        }
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const Update = async (e) => {
    e.preventDefault();

    if (image) {
      const fileExt = image.name.split(".").pop();

      const upload = storage
        .ref(`image/${userAuth.uid + "." + fileExt}`)
        .put(image);
      const date = new Date().getDate().toString();
      const year = new Date().getFullYear().toString();
      const month = new Date().getMonth().toString();
      const tday = `${date}/${month}${year}`;
      upload.on(
        "state_changed",
        (snapshot) => {
          const progress = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          setProgress(progress);
        },
        (error) => {
          console.log(error);
          alert(error.message);
        },
        () => {
          setProgress(0);
          storage
            .ref("image")
            .child(userAuth.uid + "." + fileExt)
            .getDownloadURL()
            .then((url) => {
              auth.currentUser.updateProfile({
                displayName: username,
                photoURL:url
              });
              // db.collection("posts").add({
              //   timestamp: firebase.firestore.FieldValue.serverTimestamp(),
              //   caption: caption ? caption : "",
              //   imageUrl: url,
              //   username: props.user,
              //   profilePic: "",
              //   date: tday,
              // });
              setProgress(10);
              if (
                username == "" ||
                email == "" ||
                email == "" ||
                dob == "" ||
                addr == "" ||
                mno == "" ||
                id == ""||
                gard == ""
              ) {
                alert("Invalid Details");
                setProgress(0);
                return;
              } else {
                if (email != userAuth.email) {
                  setProgress(20);
                  auth.currentUser
                    .updateEmail(email)
                    .then(function () {
                      db.collection("user/")
                        .doc(email)
                        .set({
                          email: email,
                          name: username,
                          no: mno,
                          dob: dob,
                          addr: addr,
                          gard: gard,
                          id: id,
                          propic: url,
                          vid: vid,
                          status: status,
                        })
                        .then(() => {
                          window.location.reload();
                        });
                    })
                    .catch(function (error) {
                      alert("Invalid Details");
                      setProgress(0);
                    });
                  setProgress(100);
                } else if (username != userAuth.displayName || url) {
                  setProgress(0);
                  setPic(url);
                  auth.currentUser
                    .updateProfile({
                      displayName: username,
                      photoURL: url,
                    })
                    .then(() => {
                      setProgress(60);
                      db.collection("user/")
                        .doc(email)
                        .set({
                          email: email,
                          name: username,
                          no: mno,
                          dob: dob,
                          addr: addr,
                          gard: gard,
                          id: id,
                          propic: url,
                          vid: vid,
                          status: status,
                        })
                        .then(() => {
                          setProgress(10);
                          window.location.reload();
                          handleClose();
                        });
                    });
                } else {
                  setProgress(29);
                  db.collection("user/")
                    .doc(email)
                    .set({
                      email: email,
                      name: username,
                      no: mno,
                      dob: dob,
                      addr: addr,
                      gard: gard,
                      id: id,
                      propic: url,
                      vid: vid,
                      status: status,
                    })
                    .then(() => {
                      setProgress(100);
                      window.location.reload();
                      handleClose();
                    });
                }
              }
              setProgress(0);
              setImage(null);
              setFile(null);
            });
        }
      );
    } else {
      if (
        username == "" ||
        email == "" ||
        email == "" ||
        dob == "" ||
        addr == "" ||
        mno == "" ||
        id == ""||
        gard == ""
      ) {
        alert("Invalid Details");
        setProgress(0);
        return;
      } else {
        setProgress(0);
        if (email != userAuth.email) {
          setProgress(20);
          auth.currentUser
            .updateEmail(email)
            .then(function () {
              setProgress(60);
              db.collection("user/")
                .doc(email)
                .set({
                  email: email,
                  name: username,
                  no: mno,
                  dob: dob,
                  addr: addr,
                  gard: gard,
                  id: id,
                  propic: pic,
                  vid: vid,
                  status: status,
                })
                .then(() => {
                  window.location.reload();
                });
              setProgress(100);
            })
            .catch(function (error) {
              setProgress(0);
              alert("Invalid Details");
            });
        } else if (username != userAuth.displayName) {
          setProgress(10);
          auth.currentUser
            .updateProfile({
              displayName: username,
            })
            .then(() => {
              setProgress(30);
              db.collection("user/")
                .doc(email)
                .set({
                  email: email,
                  name: username,
                  no: mno,
                  dob: dob,
                  addr: addr,
                  gard: gard,
                  id: id,
                  propic: pic,
                  vid: vid,
                  status: status,
                })
                .then(() => {
                  setProgress(100);
                  window.location.reload();
                  handleClose();
                });
            });
        } else {
          setProgress(30);
          db.collection("user/")
            .doc(email)
            .set({
              email: email,
              name: username,
              no: mno,
              dob: dob,
              addr: addr,
              gard: gard,
              id: id,
              propic: pic,
              vid: vid,
              status: status,
            })
            .then(() => {
              setProgress(100);
              window.location.reload();
              handleClose();
            });
        }
      }
    }
  };
  const body = (
    <div style={modalStyle} className={classes.paper}>
      <form className={classes.form} onSubmit={Update}>
        <div className="profile_container1">
          <img
            src={file ? file : pic}
            alt="Italian Trulli"
            className="profile_container_pic1"
          ></img>
          <input
            onChange={handleChange}
            accept="image/*"
            className={classes.input}
            id="contained-button-file"
            multiple
            type="file"
            style={{ display: "none" }}
          />
          <label htmlFor="contained-button-file">
            <a className="proselect">Select new Profile Picture</a>
          </label>
        </div>
        <TextField
          onChange={(e) => {
            setUsername(e.target.value);
          }}
          required={true}
          type="text"
          value={username}
          label="Full Name"
          variant="outlined"
          className={classes.input}
        />
        <TextField
          onChange={(e) => {
            setEmail(e.target.value);
          }}
          required={true}
          type="email"
          value={email}
          label="Email Id"
          variant="outlined"
          className={classes.input}
        />

        <TextField
          onChange={(e) => {
            setAdd(e.target.value);
          }}
          required={true}
          type="text"
          value={addr}
          label="Address"
          variant="outlined"
          className={classes.input}
        />
        <TextField
          onChange={(e) => {
            setMno(e.target.value);
          }}
          required={true}
          type="number"
          value={mno}
          label="Mobile No."
          variant="outlined"
          className={classes.input}
        />
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <DatePicker
            variant="inline"
            inputVariant="outlined"
            label="DOB*"
            format="MM/dd/yyyy"
            value={dob}
            InputAdornmentProps={{ position: "start" }}
            onChange={setDob}
            className={classes.input}
          />
        </MuiPickersUtilsProvider>
        <TextField
          onChange={(e) => {
            setGard(e.target.value);
          }}
          required={true}
          type="text"
          value={gard}
          variant="outlined"
          label="Guardian/Father name"
          className={classes.input}
        />
        <TextField
          onChange={(e) => {
            setId(e.target.value);
          }}
          required={true}
          type="text"
          value={id}
          variant="outlined"
          label="Driving Licence/Student Id"
          className={classes.input}
        />
        <Button
          type="submit"
          disabled={state ? true : false}
          className={classes.btn}
          id="btn"
        >
          Update Info
        </Button>
        <LinearProgress
          variant="determinate"
          style={{ width: "100%" }}
          max="100"
          value={progress}
        />
      </form>
    </div>
  );

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        disablePortal
        disableEnforceFocus
        disableAutoFocus
        aria-labelledby="server-modal-title"
        aria-describedby="server-modal-description"
      >
        {body}
      </Modal>
    </div>
  );
}
