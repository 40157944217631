import React, { useState, useEffect, useCallback, useContext } from "react";
import { withRouter } from "react-router";
import { Button, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { auth, db } from "../firebase/firebase";
import Alert from "@material-ui/lab/Alert";
import "./style/Style.css";
import LinearProgress from "@material-ui/core/LinearProgress";
import { UserProvider, UserContext } from "./componentes/UserContext";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
const useStyles = makeStyles((theme) => ({
  btn: {
    backgroundColor: "#eca742",
    color: "#fff",
    marginTop: 10,
    marginBottom: "10px",
  },
  form: {
    display: "flex",
    flexDirection: "column",
  },
  input: {
    margin: 5,
  },
  alert: {
    marginBottom: 10,
  },
}));

function ResetPassword(props) {
  const [email, setEmail] = useState();
  const classes = useStyles();
  const [state, setState] = useState(false);
  const [error, setError] = useState(false);

  function resetPass(e) {
    e.preventDefault();
    setState(true);
    setError(false);

    
    auth
      .sendPasswordResetEmail(email)
      .then((data) => {
        alert("Please check your Mail");
        props.home();
        //props.history.push("/")

      })
      .catch((e) => {
        setState(false);
        setError(true);
      });
  }
  return (
    <div className="login">
      <form className={classes.form}>
        {error ? (
          <Alert severity="error" className={classes.alert}>
            Account not fount
          </Alert>
        ) : (
          ""
        )}
        <TextField
          onChange={(e) => {
            setEmail(e.target.value);
          }}
          required={true}
          type="email"
          value={email}
          label="Email Id"
          className={classes.input}
          variant="outlined"
        />
        <Button
          type="submit"
          disabled={state ? true : false}
          id="btn"
          onClick={resetPass}
          className={classes.btn}
        >
          Reset Password
        </Button>
        {state ? <LinearProgress /> : ""}
      </form>
      <a className="login_reset_password" onClick={() => props.home()}>
        Back to Login
      </a>
    </div>
  );
}

function Login(props) {
  const classes = useStyles();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [state, setState] = useState(false);
  const [error, setError] = useState(false);

  function Signin(e) {
    e.preventDefault();
    setState(true);
    setError(false);
    auth
      .signInWithEmailAndPassword(email, password)
      .then((data) =>{ 
        setError(false);
      })
      .catch((e) => {
        console.log(e)
        setState(false);
        setError(true);
      });
  }

  return (
    <div className="login">
      <form className={classes.form}>
        {error ? (
          <Alert severity="error" className={classes.alert}>
            Invalid credentials
          </Alert>
        ) : (
          ""
        )}
        <TextField
          onChange={(e) => {
            setEmail(e.target.value);
          }}
          required={true}
          type="email"
          value={email}
          label="Email Id"
          className={classes.input}
          variant="outlined"
        />
        <TextField
          onChange={(e) => {
            setPassword(e.target.value);
          }}
          required={true}
          type="password"
          value={password}
          variant="outlined"
          label="Password"
          className={classes.input}
        />
        <a className="login_reset_password" onClick={props.rePassword}>
          Forgot password!
        </a>
        <Button
          type="submit"
          disabled={state ? true : false}
          id="btn"
          onClick={Signin}
          className={classes.btn}
        >
          Login
        </Button>
        {state ? <LinearProgress /> : ""}
      </form>
    </div>
  );
}

function CreateAccount(props) {
  const classes = useStyles();
  const [username, setUsername] = useState();
  const [email, setEmail] = useState();
  const [dob, setDob] = useState(new Date());
  const [addr, setAdd] = useState();
  const [gard, setGard] = useState();
  const [mno, setMno] = useState();
  const [id, setId] = useState();
  const [password, setPassword] = useState();
  const [state, setState] = useState(false);
  const [error, setError] = useState(false);
  const Register = (e) => {
    setState(true);
    setError(false);
    e.preventDefault();
    if (
      username == "" ||
      email == "" ||
      email == "" ||
      dob == "" ||
      addr == "" ||
      mno == "" ||
      id == "" ||
      password == ""||
      gard == ""
    ) {
      setError(true);
      setState(false);
      return;
    } else {
      auth
        .createUserWithEmailAndPassword(email, password)
        .then((data) => {
          auth.onAuthStateChanged((authUser) => {
            console.log(authUser);
            auth.currentUser.updateProfile({
              displayName: username,
              photoURL:"https://firebasestorage.googleapis.com/v0/b/auth-f01d1.appspot.com/o/R.jpg?alt=media&token=5317d75c-e36a-4e9f-aaef-98db6daec6fb"
            });
            authUser.updateProfile({
              displayName: username,
              photoURL:"https://firebasestorage.googleapis.com/v0/b/auth-f01d1.appspot.com/o/R.jpg?alt=media&token=5317d75c-e36a-4e9f-aaef-98db6daec6fb"
            
            })
            db.collection("user/").doc(email).set({
              email: email,
              name: username,
              no: mno,
              dob: dob,
              addr: addr,
              gard: gard,
              id: id,
              propic:
                "https://firebasestorage.googleapis.com/v0/b/auth-f01d1.appspot.com/o/R.jpg?alt=media&token=5317d75c-e36a-4e9f-aaef-98db6daec6fb",
              vid: "",
              status: "0",
            });
            setError(false);
            setState(false);
            return;
          });
        })
        .catch((error) => setError(true));
      setState(false);
    }

    setState(false);
  };

  return (
    <div className="signup">
      <form className={classes.form} onSubmit={Register}>
        {error ? (
          <Alert severity="error" className={classes.alert}>
            Invalid credentials or Email already exist
          </Alert>
        ) : (
          ""
        )}
        <TextField
          onChange={(e) => {
            setUsername(e.target.value);
          }}
          required={true}
          type="text"
          value={username}
          label="Full Name"
          variant="outlined"
          className={classes.input}
        />
        <TextField
          onChange={(e) => {
            setEmail(e.target.value);
          }}
          required={true}
          type="email"
          value={email}
          label="Email Id"
          variant="outlined"
          className={classes.input}
        />

        <TextField
          onChange={(e) => {
            setAdd(e.target.value);
          }}
          required={true}
          type="text"
          value={addr}
          label="Address"
          variant="outlined"
          className={classes.input}
        />
        <TextField
          onChange={(e) => {
            setMno(e.target.value);
          }}
          required={true}
          type="number"
          value={mno}
          label="Mobile No."
          variant="outlined"
          className={classes.input}
        />
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <DatePicker
            variant="inline"
            inputVariant="outlined"
            label="DOB*"
            format="MM/dd/yyyy"
            value={dob}
            InputAdornmentProps={{ position: "start" }}
            onChange={setDob}
            className={classes.input}
          />
        </MuiPickersUtilsProvider>
        <TextField
          onChange={(e) => {
            setGard(e.target.value);
          }}
          required={true}
          type="text"
          value={gard}
          variant="outlined"
          label="Guardian/Father name"
          className={classes.input}
        />
        <TextField
          onChange={(e) => {
            setId(e.target.value);
          }}
          required={true}
          type="text"
          value={id}
          variant="outlined"
          label="Driving Licence/Student Id"
          className={classes.input}
        />
        <TextField
          onChange={(e) => {
            setPassword(e.target.value);
          }}
          required={true}
          type="password"
          value={password}
          variant="outlined"
          label="Password"
          className={classes.input}
          minLength={6}
          maxLength={12}
        />
        <Button
          type="submit"
          disabled={state ? true : false}
          className={classes.btn}
          id="btn"
          
        >
          Create Account
        </Button>
        {state ? <LinearProgress /> : ""}
      </form>
    </div>
  );
}
function Signup(props) {
  const [state, setState] = useState(true);
  const userAuth = useContext(UserContext);
  const [user, setUser] = useState();
  const [reset, setReset] = useState(false);
  useEffect(() => {
    const authV = () => {
      if (userAuth) {
        console.log(userAuth);
        props.history.push("/Home");
        return;
      } else {
        props.history.push("/");
      }
    };
    authV();
  }, [userAuth]);
  return (
    <div className="sign">
      <div className="container">
        {reset ? (
          <ResetPassword props={props} home={() => setReset(false)} />
        ) : (
          <div>
            <div className="selector">
              <span
                onClick={() => {
                  setState(true);
                }}
                style={{
                  backgroundColor: state ? "#eca742" : "#fff",
                  color: state ? "#fff" : "",
                }}
              >
                Login
              </span>
              <span
                onClick={() => {
                  setState(false);
                }}
                style={{
                  backgroundColor: state ? "#fff" : "#eca742",
                  color: state ? "" : "#fff",
                }}
              >
                Create Account
              </span>
            </div>
            {state ? (
              <Login props={props} rePassword={() => setReset(true)} />
            ) : (
              <CreateAccount props={props} />
            )}
          </div>
        )}
      </div>
      <span
        className="footer_copy
      " style={{marginTop:state?'15%':0}}
      >
        © 2021 Cairns Has Talents. Designed & Developed by{" "}
        <a href="https://www.woxro.com/" style={{color:'#fff'}}>Woxro</a>
      </span>
    </div>
  );
}

export default withRouter(Signup);
